/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Tooltip,
  Stack,
  FormLabel,
  TextField,
  // Modal,
  Typography,
  // IconButton,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
  Paper,
  // Avatar,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useLocation, useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { SubModuleData } from './type';
import { ModuleData } from '../module/type';
import { Severity } from '../../components/Toast/type';
import { Loader, Toast } from '../../components';

const initialModuleState: SubModuleData = {
  id: '', name: '', code: '', module: { id: '', name: '', code: ''}, module_id: '', 
};

const FormModal = ({
  subModuleEdit,
}: any) => {
  const location = useLocation();
  const [subModule, setSubModule] = useState<SubModuleData>(initialModuleState);
  const navigate = useNavigate();
  const [disable, setDisable] = useState<boolean>(true);
  const [error, setError] = useState<{ [key: string]: boolean }>({});
  const [modules, setModules] = useState<ModuleData[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<{ message: string, status: number, open: boolean, severity: Severity }>({
    message: '', status: 500, severity: Severity.ERROR, open: false,
  });
  const customStyles = {
    width: '100%',
    overflow: 'hidden',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '8px !important', // Note: Property name should be camelCase
    fontWeight: 500,
  };

  useEffect(() => {
    let isDisabled;
    if (isEdit) {
      isDisabled = subModule?.code?.length > 0 && subModule?.name?.length > 0;
    } else {
      isDisabled = subModule?.code?.length > 0 && subModule?.name?.length > 0 && subModule?.module_id;
    }
    if (location.state && subModule) {
      const { editData } = location.state;
      isDisabled = subModule?.name !== editData?.name || subModule?.module?.name !== editData?.module?.name;
    }
    setDisable(!isDisabled);
  }, [subModule, isEdit, location.state]);

  useEffect(() => {
    const fetchModules = async () => {
      const { data: { data = {} } = {} } = await axios.get(`${process.env.REACT_APP_API_URL}/module`);
      setModules(data);
    };
    fetchModules();
  }, []);

  useEffect(() => {
    // When moduleEdit changes (probably when the edit button is clicked), update the local state
    setSubModule(subModuleEdit);
  }, [subModuleEdit]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const { name, value } = e.target;
  
    const isValueEmpty = typeof value === 'string' && value.trim() === '';
  
    setError((prevErrors) => ({
      ...prevErrors,
      [name]: isValueEmpty,
    }));
  
    setSubModule((prev) => ({
      ...prev,
      [name]: name === 'code' ? value.toLocaleUpperCase() : value,
    }));
  };
  
  const addModules = async (formData: Partial<SubModuleData>) => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/sub-module`, formData);
      if (data.error) {
        setInfo({
          ...info, message: data.message, open: true, severity: Severity.ERROR,
        });
        setLoading(false);
      } else {
        const { message } = data;
        setInfo({
          ...info, message, open: true, severity: Severity.SUCCESS,
        });
        setTimeout(() => {
          navigate('/sub-module');
          setLoading(false);
        }, 2000);
      }
    } catch (err: any) {
      setInfo({
        open: true,
        message: err.response.data.message,
        status: err.response.status,
        severity: Severity.ERROR,
      });
      setLoading(false);
    }
  };

  const editModules = async (formData: Partial<SubModuleData>) => {
    try {
      setLoading(true);
      const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/sub-module`, formData);
      if (data.error) {
        setInfo({
          ...info, message: data.message, open: true, severity: Severity.ERROR,
        });
        setLoading(false);
      } else {
        const { message } = data;
        setInfo({
          ...info, message, open: true, severity: Severity.SUCCESS,
        });
        setTimeout(() => {
          navigate('/sub-module');
          setLoading(false);
        }, 2000);
      }
    } catch (err: any) {
      setInfo({
        open: true,
        message: err.response.data.message,
        status: err.response.status,
        severity: Severity.ERROR,
      });
      setLoading(false);
    }
  };

  const handleEdit = async (id: string) => {
    try {
      setIsEdit(true);
      const { data: { data = {} } = {} } = await axios.get(`${process.env.REACT_APP_API_URL}/sub-module/${id}`);
      setSubModule(data);
    } catch (err: any) {
      setInfo({
        open: true,
        message: err.response.data.message,
        status: err.response.status,
        severity: Severity.ERROR,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    // When moduleEdit changes (probably when the edit button is clicked), update the local state
    if (location.state) {
      const { editData } = location.state;
      handleEdit(editData.id);
    }
  }, [location.state, subModuleEdit]);
  const handleBack = () => {
    navigate('/sub-module?param=0');
  };
  const handleUpdate = (dataToUpdate: Partial<SubModuleData>) => {
    setLoading(true);
    if (isEdit) {
      editModules(dataToUpdate);
    } else {
      addModules(dataToUpdate);
    }
  };
  const EditModule = () => {
    handleUpdate(subModule);
  };

  const AddModule = () => {
    handleUpdate(subModule);
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return isEdit ? EditModule() : AddModule();
  };
  return (
    <Grid item lg={12} xs={12} sm={12}>
      {loading && <Loader open={loading} />}
      {info.open && (
        <Toast
          open={info.open}
          message={info.message}
          severity={info.severity}
          onClose={() => setInfo({ ...info, open: false })}
        />
      )}
      <Paper sx={customStyles}>
        <Box mt={2} sx={{ background: 'white' }} p={4}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">
              Add Sub Module
            </Typography>
          </Stack>
          <Box component="form" onSubmit={handleSubmit}>
            <Stack direction="row" alignItems="center" my={2}>
              <FormLabel
                sx={{
                  fontSize: '1rem', fontWeight: '900', m: '0', lineHeight: '2rem', color: '#000',
                }}
                required
              >
                Module
              </FormLabel>
              <Tooltip title="Name is required" sx={{ marginLeft: '0.5rem' }}>
                <InfoIcon />
              </Tooltip>
            </Stack>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Select Module</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={subModule?.module_id || ''}
                label="Select Module"
                name="module_id"
                onChange={handleChange}
              >
                {modules.map((module) => (
                  <MenuItem key={module.id} value={module.id}>
                    {module.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack direction="row" alignItems="center" my={2}>
              <FormLabel
                sx={{
                  fontSize: '1rem', fontWeight: '900', m: '0', lineHeight: '2rem', color: '#000',
                }}
                required
              >
                Name
              </FormLabel>
              <Tooltip title="Name is required" sx={{ marginLeft: '0.5rem' }}>
                <InfoIcon />
              </Tooltip>
            </Stack>
            <TextField
              error={error?.name}
              fullWidth
              name="name"
              id="outlined-basic"
              variant="outlined"
              helperText={error?.name ? 'Please enter module name' : ''}
              onChange={handleChange}
              value={subModule?.name}
              inputProps={{ maxLength: 30 }}
            />
            <Stack direction="row" alignItems="center" my={2} mt={4}>
              <FormLabel
                sx={{
                  fontSize: '1rem', fontWeight: '900', color: '#000',
                }}
                required
              >
                Code
              </FormLabel>
              <Tooltip title="Code is required" sx={{ marginLeft: '0.5rem' }}>
                <InfoIcon />
              </Tooltip>
            </Stack>
            <TextField
              error={error?.code}
              name="code"
              fullWidth
              inputProps={{
                readOnly: !!isEdit,
                maxLength: 30,
              }}
              id="outlined-basic"
              variant="outlined"
              helperText={error.code ? 'Please enter module code' : ''}
              onChange={handleChange}
              value={subModule?.code}
            />
            <Stack direction="row" justifyContent="space-between" mt={5}>
              <Button onClick={handleBack} variant="contained">Back</Button>
              <Button disabled={disable} type="submit" variant="contained">{isEdit ? 'Update' : 'Submit'}</Button>
            </Stack>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default FormModal;
