import React, { useEffect, useState } from 'react';
import {
  Button, Stack, Typography, Paper, Box, List, ListItem, ListItemIcon, ListItemText, Grid, Avatar,
} from '@mui/material';
import axios from 'axios';
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FormModal from './FormModal';
import { SubModuleData } from './type';
import { Severity } from '../../components/Toast/type';
import { Toast, Loader } from '../../components';
import AlertDialog from '../../components/Alertdialog/Alertdialog';
import { truncateText } from '../../common/helper';

let content;

const intialState: SubModuleData = {
  id: '',
  name: '',
  module: {
    id: '',
    name: '',
    code: '',
  },
  module_id: '',
  code: '',
};

const SubModule = () => {
  const [rows, setRows] = useState<SubModuleData[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<{ message: string, status: number, open: boolean, severity: Severity }>({
    message: '', status: 500, severity: Severity.ERROR, open: false,
  });
  const [subModules] = useState<SubModuleData>(intialState);
  const [open, setOpen] = useState<boolean>(false);
  const [isEdit] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogid, setDialogid] = useState(String);

  useEffect(() => {
    const fetchModules = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/sub-module`);
        const { data: modules = [] } = data;
        setRows(modules);
        setLoading(false);
      } catch (err: any) {
        setInfo({
          open: true,
          message: err.response.data.message,
          status: err.response.status,
          severity: Severity.ERROR,
        });
        setLoading(false);
      }
    };
    fetchModules();
  }, []);

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/sub-module/${id}`);
      const { data: result, message } = data;
      setOpenDialog(false);
      setTimeout(() => {
        window.location.reload();
      }, 500);
      setInfo({
        ...info, message, open: true, severity: Severity.ERROR,
      });
      setRows(result.deletedCount === 1 ? rows.filter((row) => row.id !== id) : rows);
      setLoading(false);
    } catch (err: any) {
      setInfo({
        open: true,
        message: err.response.data.message,
        status: err.response.status,
        severity: Severity.ERROR,
      });
      setLoading(false);
    }
  };
  const handleDialog = (id: string) => {
    setDialogid(id);
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleModalClose = () => setOpen(false);

  if (loading) {
    content = <Loader open={loading} />;
  } else if (rows.length > 0) {
    content = (
      <List sx={{ padding: '15px' }} className="tbl_view">
        {rows.map((row: any) => (
          <ListItem
            key={row.id}
            sx={{
              border: '1px solid #D3D3D3', borderRadius: '10px', padding: '1rem 1rem', mb: '1rem',
            }}
          >
            <ListItemText sx={{ textAlign: 'left' }} id={row.id} primary={truncateText(row.name, 30)} />
            <ListItemText sx={{ textAlign: 'left' }} id={row.id} primary={truncateText(row.module.name, 30)} />
            <ListItemText id={row.code} primary={truncateText(row.code, 30)} />
            <ListItemIcon>
              <Edit
                className="csEdit"
                onClick={() => navigate('/sub-modules/add', {
                  state: {
                    editData: row,
                  },
                })}
                sx={{ color: '#2559C3' }}
              />
              {/* <Edit onClick={() => handleEdit(row.id)} sx={{ color: '#2559C3' }} /> */}
              <Delete onClick={() => handleDialog(row.id)} sx={{ color: '#DE4E35' }} />
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    );
  } else {
    content = <Typography variant="body1" style={{ padding: '15px', textAlign: 'center' }}>No data to display</Typography>;
  }

  return (
    <Grid flexGrow={1}>
      {loading && <Loader open={loading} />}
      {info.open && (
        <Toast
          open={info.open}
          message={info.message}
          severity={info.severity}
          onClose={() => setInfo({ ...info, open: false })}
        />
      )}
      {openDialog && <AlertDialog open={openDialog} onClose={handleClose} onDelete={() => handleDelete(dialogid)} />}
      {open && <FormModal open={open} onModalClose={handleModalClose} isEdit={isEdit} subModuleEdit={subModules} />}
      <Stack className="rbac_content_header" direction="row" justifyContent="space-between" flexGrow={1} alignItems="center" mb={2}>
        <Typography variant="h5">
          {' '}
          <Avatar className="title_icn" alt="Remy Sharp" src="../../../role_icon.svg" />
          Sub-Module
        </Typography>
        <Button className="text-capitalize" onClick={() => navigate('/sub-modules/add')} variant="contained">Add New Sub-Module + </Button>
      </Stack>
      <Paper className="hFull_screen" sx={{ width: '100%', overflow: 'hidden' }}>
        <Stack direction="row" className="tbl_view_head2" justifyContent="space-between" p={2} sx={{ backgroundColor: '#e9eef9' }}>
          <Box>Name</Box>
          <Box>Module</Box>
          <Box>Code</Box>
          <Box>Actions</Box>
        </Stack>
        {content}
      </Paper>
    </Grid>
  );
};

export default SubModule;
